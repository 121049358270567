import { blueColor, darkColor, lightBlueColor } from "@/constants/colors";
import { gotham } from "@/utils/fonts";
import { css } from "@emotion/react";
import clsx from "clsx";
import { HTMLAttributes, useMemo } from "react";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  jwType: "light" | "dark" | "lightBlue" | "lightOutline";
  type?: "submit" | "button";
  // onClick?: () => void;
}

const Button = ({ jwType, className, ...props }: Props) => {
  const cssStyle = useMemo(() => {
    let backgroundColor;
    let hoverColor;
    let border;
    let hoverBorderColor;
    switch (jwType) {
      case "light":
        backgroundColor = "#fff";
        hoverColor = blueColor;
        border = "unset";
        break;
      case "dark":
        backgroundColor = darkColor;
        hoverColor = "#0D3047";
        border = "unset";
        break;
      case "lightBlue":
        backgroundColor = lightBlueColor;
        hoverColor = "#45ABE7";
        border = "unset";
        break;
      case "lightOutline":
        backgroundColor = "transparent";
        hoverColor = "transparent";
        border = "2px solid #fff";
        hoverBorderColor = blueColor;
        break;
    }
    return css(`
        background-color: ${backgroundColor};
        height: 50px;
        border-radius: 10px;
        font-size: 16px;
        border: ${border};
        color: ${jwType === "light" ? darkColor : "#fff"};
        font-weight: 350;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: ${hoverColor};
          ${hoverBorderColor ? `border-color: ${hoverBorderColor}` : ""};
        }
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 177px;
        width: fit-content;
      `);
  }, [jwType]);

  return (
    <button
      css={cssStyle}
      className={clsx(className, gotham.className)}
      {...props}
    />
  );
};

export default Button;
