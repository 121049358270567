import {
  blueColor,
  darkColor,
  lightBlueColor,
  textColor,
} from "@/constants/colors";
import { css } from "@emotion/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Body, H9 } from "../Typography";

interface Props {
  title: string;
  description?: string;
  Icon?: React.FC;
  href: string;
  isLastItem?: boolean;
  onCloseDialog: () => void;
}

const NavbarMobileDialogItem = ({
  title,
  description,
  Icon,
  href,
  isLastItem = false,
  onCloseDialog,
}: Props) => {
  const router = useRouter();

  return (
    <Link
      href={href}
      onClick={() => {
        if (href === router.asPath) onCloseDialog();
      }}
    >
      <div
        css={css`
          display: flex;
          ${isLastItem ? "margin-bottom: 12px;" : ""}
          padding: 12px 0;
          display: flex;
          align-items: center;
        `}
      >
        {Icon ? (
          <div
            css={css`
              background-color: ${blueColor};
              width: 42px;
              height: 42px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 25px;
              color: ${lightBlueColor};
            `}
          >
            <Icon />
          </div>
        ) : (
          <div
            css={css`
              background-color: ${blueColor};
              width: 10px;
              height: 10px;
              border-radius: 100%;
            `}
          />
        )}
        <div
          css={css`
            margin-left: 14px;
          `}
        >
          <H9
            css={css`
              color: ${darkColor};
            `}
          >
            {title}
          </H9>
          {description && (
            <Body
              css={css`
                margin-top: 3px;
                color: ${textColor};
                font-size: 12px;
                line-heigth: 136%;
              `}
            >
              {description}
            </Body>
          )}
        </div>
      </div>
    </Link>
  );
};

export default NavbarMobileDialogItem;
