import { HTMLAttributes } from "react";

interface CheerleadingIconProps extends HTMLAttributes<SVGElement> {}

const CheerleadingIcon = ({ ...props }: CheerleadingIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.70503 10.434C1.66106 9.95745 1.96099 9.5166 2.42044 9.38248L18.5777 4.66611C19.1931 4.48648 19.8162 4.92405 19.8562 5.56381L20.5535 16.7219C20.5918 17.3351 20.0733 17.8373 19.4617 17.7797L3.05871 16.233C2.57976 16.1878 2.20078 15.8081 2.15659 15.3291L1.70503 10.434Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="15.6918"
        y1="6.299"
        x2="16.6992"
        y2="16.7084"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6001 17L7.0387 18.7934C7.13612 19.1917 7.46687 19.4902 7.8731 19.5464L11.4631 20.0428C12.0641 20.1259 12.6001 19.659 12.6001 19.0522V17.3733"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.63693 8.19269L5.68286 7.05528C5.68837 6.91876 5.6363 6.78663 5.54055 6.69419L4.74281 5.92405C4.55253 5.74036 4.55253 5.42489 4.74281 5.2412L5.54055 4.47106C5.6363 4.37862 5.68837 4.24649 5.68286 4.10997L5.63693 2.97256C5.62598 2.70126 5.83823 2.47819 6.09637 2.48971L7.17862 2.53798C7.30852 2.54377 7.43423 2.48904 7.52219 2.38841L8.25498 1.55001C8.42977 1.35003 8.72993 1.35003 8.90472 1.55001L9.63751 2.38841C9.72546 2.48904 9.85118 2.54377 9.98108 2.53798L11.0633 2.48971C11.3215 2.47819 11.5337 2.70126 11.5228 2.97256L11.4768 4.10997C11.4713 4.24649 11.5234 4.37862 11.6192 4.47106L12.4169 5.2412C12.6072 5.42489 12.6072 5.74036 12.4169 5.92405"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheerleadingIcon;
