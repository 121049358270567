import { clsx } from "clsx";
import { gotham, inter } from "@/utils/fonts";
import { HTMLProps } from "react";
import { css } from "@emotion/react";

interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

export const Heading = ({ className, level, ...props }: HeadingProps) => {
  const Tag: keyof JSX.IntrinsicElements = level ? `h${level}` : "div";
  return <Tag className={clsx(className, gotham.className)} {...props} />;
};

export const H1 = ({ className, ...props }: HTMLProps<HTMLHeadingElement>) => (
  <>
    <h1 className={clsx(className, gotham.className)} {...props} />
    <style jsx>{`
      * {
        font-size: 64px;
        line-height: 120%;
      }
    `}</style>
  </>
);

export const H2Css = `
        font-size: 56px;
        line-height: 120%;
`;

export const H2 = ({ className, ...props }: HTMLProps<HTMLHeadingElement>) => (
  <h2
    css={css(H2Css)}
    className={clsx(className, gotham.className)}
    {...props}
  />
);

export const H3Css = `
  font-size: 50px;
  line-height: 130%;
`;

export const H3 = ({ className, ...props }: HTMLProps<HTMLHeadingElement>) => (
  <h3
    css={css(H3Css)}
    className={clsx(className, gotham.className)}
    {...props}
  />
);

export const H4Css = `
        font-size: 32px;
        line-height: 130%;
`;

export const H4 = ({ className, ...props }: HTMLProps<HTMLHeadingElement>) => (
  <h4
    css={css(H4Css)}
    className={clsx(className, gotham.className)}
    {...props}
  />
);

export const H5Css = `
font-weight: 400;
font-size: 28px;
line-height: 130.02%;
`;

export const H5 = ({ className, ...props }: HTMLProps<HTMLHeadingElement>) => (
  <h5
    css={css`
      ${H5Css}
    `}
    className={clsx(className, gotham.className)}
    {...props}
  />
);

export const H6Css = `

        font-size: 24px;
        line-height: 140%;
`;

export const H6 = ({ className, ...props }: HTMLProps<HTMLHeadingElement>) => (
  <h6
    css={css(H6Css)}
    className={clsx(className, gotham.className)}
    {...props}
  />
);

export const H7Css = `
        font-size: 20px;
        line-height: 130%;
`;

export const H7 = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <div
    css={css(H7Css)}
    className={clsx(className, gotham.className)}
    {...props}
  />
);

export const H8_10Css = `
        font-size: 10px;
        line-height: 130%;
`;

export const H8_10 = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <div
    css={css(H8_10Css)}
    className={clsx(className, gotham.className)}
    {...props}
  />
);

export const H8_16Css = `
        font-size: 16px;
        line-height: 19px;
        font-weight: 350;
`;

export const H8_16 = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <div
    css={css(H8_16Css)}
    className={clsx(className, gotham.className)}
    {...props}
  />
);

export const H9Css = `
        font-size: 15px;
        font-weight: 350;
        line-height: 22px;
        font-style: normal;
`;

export const H9 = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <div
    css={css(H9Css)}
    className={clsx(className, gotham.className)}
    {...props}
  />
);

export const Body = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <div className={clsx(className, inter.className)} {...props} />
);

export const BodyMedium24Css = `
        font-size: 24px;
        font-weight: 500;
        line-height: 140%;
`;

export const BodyMedium24 = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <div
    css={css(BodyMedium24Css)}
    className={clsx(className, inter.className)}
    {...props}
  />
);

export const BodyMedium20Css = `
        font-size: 20px;
        font-weight: 500;
        line-height: 140%;
`;

export const BodyMedium20 = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <div
    css={css(BodyMedium20Css)}
    className={clsx(className, inter.className)}
    {...props}
  />
);

export const BodyMedium16Css = `
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
`;

export const BodyMedium16 = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <div
    css={css(BodyMedium16Css)}
    className={clsx(className, inter.className)}
    {...props}
  />
);

export const BodyMedium14Css = `
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
`;

export const BodyMedium14 = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <div
    css={css(BodyMedium14Css)}
    className={clsx(className, inter.className)}
    {...props}
  />
);

export const ListItem = ({ className, ...props }: HTMLProps<HTMLLIElement>) => (
  <li className={clsx(className, inter.className)} {...props} />
);

export const BodyRegular14Css = `
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
`;

export const BodyRegular14 = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <div
    css={css(BodyRegular14Css)}
    className={clsx(className, inter.className)}
    {...props}
  />
);

export const BodySemiBold12 = ({
  className,
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <>
    <div className={clsx(className, inter.className)} {...props} />
    <style jsx>{`
      * {
        font-size: 12px;
        font-weight: 600;
        line-height: 140%;
      }
    `}</style>
  </>
);
