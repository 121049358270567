import { HTMLAttributes } from "react";

interface FootballIconProps extends HTMLAttributes<SVGElement> {}

const FootballIcon = ({ ...props }: FootballIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4303 7.56941L7.56878 14.4309M11.9799 11.9805L10.0192 10.0198M13.9401 10.0198L11.9799 8.05958M19.8218 9.03948L12.9602 2.17796M9.03885 19.8224L2.17733 12.9609M10.0192 13.9408L8.05895 11.9805M19.3175 2.68261C21.3045 4.66963 20.9851 11.798 16.3914 16.3916C11.7977 20.9853 4.64664 21.282 2.68237 19.3177C0.718089 17.3535 1.01479 10.2024 5.60845 5.60869C10.2021 1.01503 17.3305 0.695592 19.3175 2.68261Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FootballIcon;
