import { backgroundColor, darkColor } from "@/constants/colors";
import useNavbarFontColor from "@/hooks/useNavbarFontColor";
import { css } from "@emotion/react";
import Link, { LinkProps } from "next/link";
import { ReactNode } from "react";
import { H9 } from "../Typography";

interface Props extends LinkProps {
  children?: ReactNode;
  isGetStarted?: boolean;
  isPricing?: boolean;
}

const NavbarDesktopLink = ({
  children,
  isGetStarted = false,
  isPricing = false,
  ...props
}: Props) => {
  const color = useNavbarFontColor();
  const isFloating = color === "#243B4A";
  const borderColor = isFloating ? color : backgroundColor;
  return (
    <Link
      css={css`
        position: relative;
        ${isPricing &&
        `
        &:hover {
          .line-under-header-folder {
            width: 14px;
            opacity: 1;
          }
        }
        `}
      `}
      {...props}
    >
      <H9
        css={css`
          color: ${isGetStarted ? "#fff" : color};
          padding: 8px;
          border-radius: 10px;
          border: 2px solid transparent;
          transition: border-color 0.2s ease-in-out;
          background-color: ${isGetStarted ? darkColor : "transparent"};
          width: ${isGetStarted ? "124px" : undefined};
          text-align: center;
          ${!isPricing &&
          `
            &:hover {
              border-color: ${isGetStarted ? "transparent" : borderColor};
              background-color: ${isGetStarted ? "#183345" : "none"};
            }
            `};
        `}
      >
        {children}
        {isGetStarted && (
          <svg
            style={{ marginLeft: 12 }}
            width="6"
            height="12"
            viewBox="0 0 6 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L5 6L0.999999 11"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </H9>
      <div
        className="line-under-header-folder"
        css={css`
          position: absolute;
          bottom: 0;
          margin: auto;
          left: 0;
          right: 0;
          width: 0;
          opacity: 0;
          transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out;
        `}
      >
        <hr
          css={css`
            border-top: 2px solid ${color};
          `}
        />
      </div>
    </Link>
  );
};

export default NavbarDesktopLink;
