import { mq } from "@/constants/breakpoints";
import { signUpPath } from "@/constants/paths";
import { css, SerializedStyles } from "@emotion/react";
import Link from "next/link";
import Button from "./Button";

interface GetStartedButtonProps {
  onClick?: () => void;
  btnCss?: SerializedStyles;
}

const GetStartedButton = ({ onClick, btnCss }: GetStartedButtonProps) => {
  return (
    <Link
      href={signUpPath}
      onClick={onClick}
      css={css`
        display: block;
        width: fit-content;
      `}
    >
      <Button
        jwType="dark"
        css={css`
          ${mq["xs"]} {
            min-width: 124px;
            height: 40px;
            ${btnCss};
          }
        `}
      >
        <span
          css={css`
            margin-right: 12px;
            ${mq["xs"]} {
              font-size: 14px;
              font-weight: 350px;
              line-height: 16.8px;
            }
          `}
        >
          Get Started
        </span>
        <svg
          width="6"
          height="12"
          viewBox="0 0 6 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 6L0.999999 11"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Button>
    </Link>
  );
};

export default GetStartedButton;
