import Image from "next/image";
import { css } from "@emotion/react";
import { useMemo } from "react";
import { iconColor } from "@/constants/colors";
import youtubeImage from "../../../public/images/footer/socials/youtube.png";
import facebookImage from "../../../public/images/footer/socials/facebook.png";
import linkedinImage from "../../../public/images/footer/socials/linkedin.png";
import instagramImage from "../../../public/images/footer/socials/instagram.png";

interface FooterSocialProps {
  type: "youtube" | "facebook" | "instagram" | "linkedin";
}

const FooterSocial = ({ type }: FooterSocialProps) => {
  const { href, imageSrc, imageAlt } = useMemo(() => {
    switch (type) {
      case "youtube":
        return {
          href: "https://www.youtube.com/user/JerseyWatchNetwork",
          imageSrc: youtubeImage,
          imageAlt: "Jersey Watch Youtube",
        };
      case "facebook":
        return {
          href: "https://www.facebook.com/JerseyWatch",
          imageSrc: facebookImage,
          imageAlt: "Jersey Watch Facebook",
        };
      case "instagram":
        return {
          href: "https://www.instagram.com/jersey.watch/",
          imageSrc: instagramImage,
          imageAlt: "Jersey Watch Instagram",
        };
      case "linkedin":
        return {
          href: "https://www.linkedin.com/company/jersey-watch/",
          imageSrc: linkedinImage,
          imageAlt: "Jersey Watch Linkedin",
        };
    }
  }, [type]);

  return (
    <a
      href={href}
      css={css`
        background-color: #fff;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        transition: background-color 0.2s ease-in-out;
        &:hover {
          background-color: ${iconColor};
        }
      `}
    >
      <Image
        src={imageSrc}
        alt={imageAlt}
        quality={100}
        css={css`
          width: 55%;
          height: auto;
        `}
      />
    </a>
  );
};

export default FooterSocial;
