import { darkColor, lightBlueColor } from "@/constants/colors";
import navbarData from "@/constants/navbarData";
import {
  demoPath,
  pricingPath,
  signInUrl,
  signUpPath,
} from "@/constants/paths";
import BigCrossIcon from "@/svg/BigCrossIcon";
import JWHeaderLogo from "@/svg/JWHeaderLogo";
import { css } from "@emotion/react";
import Link from "next/link";
import { useState } from "react";
import Modal from "react-modal";
import Button from "../Buttons/Button";
import GetStartedButton from "../Buttons/GetStartedButton";
import HR from "../HR/HR";
import { H8_16 } from "../Typography";
import NavbarMobileDialogAccordion from "./NavbarMobileDialogAccordion";
import NavbarMobileDialogItem from "./NavbarMobileDialogItem";
import { useRouter } from "next/router";

const TRANSITION_MS = 250;

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

const NavbarMobileDialog = ({ isOpen, onRequestClose }: Props) => {
  const [featuresOpen, setFeaturesOpen] = useState(false);
  const [sportsOpen, setSportsOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const router = useRouter();

  const handleClick = (path: string) => () => {
    if (path === router.asPath) onRequestClose();
  };

  return (
    <>
      <style global jsx>
        {`
          .ReactModal__Overlay {
            opacity: 0;
            transition: opacity ${TRANSITION_MS}ms ease-in-out;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }
        `}
      </style>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={{
          content: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            padding: 0,
            border: 0,
          },
        }}
        contentLabel="modal"
        closeTimeoutMS={TRANSITION_MS}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <div
              css={css`
                height: 60px;
                padding-left: 20px;
              `}
            >
              <Link
                href="/"
                aria-label="Jersey Watch Logo"
                onClick={handleClick("/")}
                css={css`
                  display: block;
                `}
              >
                <JWHeaderLogo
                  css={css`
                    height: 60px;
                    color: ${lightBlueColor};
                  `}
                />
              </Link>
            </div>
            <div
              role="button"
              onClick={onRequestClose}
              css={css`
                height: 60px;
                width: 60px;
                color: ${lightBlueColor};
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <BigCrossIcon />
            </div>
          </div>
          <div
            css={css`
              padding: 0 20px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              flex-grow: 1;
            `}
          >
            <NavbarMobileDialogAccordion
              label="Features"
              open={featuresOpen}
              onClick={() => setFeaturesOpen(!featuresOpen)}
            >
              {navbarData.features.map(
                ({ title, description, Icon, href }, index) => (
                  <NavbarMobileDialogItem
                    onCloseDialog={onRequestClose}
                    key={index}
                    title={title}
                    Icon={Icon}
                    description={description}
                    href={href}
                    isLastItem={index === navbarData.features.length - 1}
                  />
                )
              )}
            </NavbarMobileDialogAccordion>
            <HR />
            <NavbarMobileDialogAccordion
              label="Sports"
              open={sportsOpen}
              onClick={() => setSportsOpen(!sportsOpen)}
            >
              {navbarData.sports.map(({ title, Icon, href }, index) => (
                <NavbarMobileDialogItem
                  onCloseDialog={onRequestClose}
                  key={index}
                  title={title}
                  Icon={Icon}
                  href={href}
                  isLastItem={index === navbarData.sports.length - 1}
                />
              ))}
            </NavbarMobileDialogAccordion>
            <HR />
            <Link href="/pricing" onClick={handleClick(pricingPath)}>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 20px 0;
                `}
              >
                <H8_16
                  css={css`
                    color: ${darkColor};
                  `}
                >
                  Pricing
                </H8_16>
              </div>
            </Link>
            <HR />
            <NavbarMobileDialogAccordion
              label="Resources"
              open={resourcesOpen}
              onClick={() => setResourcesOpen(!resourcesOpen)}
            >
              {navbarData.resources.map(({ title, href }, index) => {
                if (href === pricingPath) return null;
                return (
                  <NavbarMobileDialogItem
                    onCloseDialog={onRequestClose}
                    key={index}
                    title={title}
                    href={href}
                    isLastItem={index === navbarData.resources.length - 1}
                  />
                );
              })}
            </NavbarMobileDialogAccordion>
          </div>
          <div
            css={css`
              padding-bottom: 30px;
              margin: auto;
              display: flex;
              flex-direction: column;
            `}
          >
            {/* @ts-ignore */}
            <GetStartedButton onClick={handleClick(signUpPath)} />
            <Link href={demoPath} passHref onClick={handleClick(demoPath)}>
              <Button
                jwType="light"
                css={css`
                  margin-top: 24px;
                `}
              >
                Watch Demo
              </Button>
            </Link>
            <Link href={signInUrl}>
              <H8_16
                css={css`
                  color: ${darkColor};
                  margin-top: 30px;
                  text-align: center;
                `}
              >
                Login
              </H8_16>
            </Link>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NavbarMobileDialog;
