import Script from "next/script";

interface HelpScoutProps {}

const HelpScout = ({}: HelpScoutProps) => {
  return (
    <>
      <Script
        id="hs-script-loader"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
         `,
        }}
      />
      <Script
        id="hs-beacon-init"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            window.Beacon('init', 'ae6ca6b6-48de-4d45-b75e-720fe525f306');
            window.Beacon('config', {
                display: {
                    style: 'manual'
                }
            });
          `,
        }}
      />
    </>
  );
};

export default HelpScout;
