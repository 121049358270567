import { HTMLAttributes } from "react";

interface SoccerIconProps extends HTMLAttributes<SVGElement> {}

const SoccerIcon = ({ ...props }: SoccerIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0001 7.20978L14.6045 9.82872M11.0001 7.20978L7.39574 9.82872M11.0001 7.20978V4.36722M14.6045 9.82872L13.2277 14.066H8.77343M14.6045 9.82872L17.159 8.63106M7.39574 9.82872L8.77248 14.066L7.21002 16.2112M7.39574 9.82872L4.84122 8.63106M11.0001 4.36722L7.80128 2.07801M11.0001 4.36722L14.1989 2.07801M17.159 8.63106L20.4279 11.9474M17.159 8.63106L18.1065 4.73201M13.2287 14.066L14.7902 16.2112M14.7902 16.2112L19.2075 15.7375M14.7902 16.2112L12.4214 20.3689M7.21002 16.2112L2.79268 15.7375M7.21002 16.2112L9.57882 20.3689M4.84122 8.63106L1.57228 11.9474M4.84122 8.63106L3.8937 4.73201M20.4753 10.9999C20.4753 16.233 16.2333 20.4751 11.0001 20.4751C5.76695 20.4751 1.5249 16.233 1.5249 10.9999C1.5249 5.76671 5.76695 1.52466 11.0001 1.52466C16.2333 1.52466 20.4753 5.76671 20.4753 10.9999Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SoccerIcon;
