import { HTMLAttributes } from "react";

interface CardIconProps extends HTMLAttributes<SVGElement> {}

const CardIcon = ({ ...props }: CardIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.92773 6.448C1.92773 5.34343 2.82316 4.448 3.92773 4.448H18.0717C19.1763 4.448 20.0717 5.34343 20.0717 6.448V15.552C20.0717 16.6566 19.1763 17.552 18.0717 17.552H3.92774C2.82317 17.552 1.92773 16.6566 1.92773 15.552V6.448Z"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="2.92773"
        y1="8.48804"
        x2="19.0717"
        y2="8.48804"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CardIcon;
