import { HTMLAttributes } from "react";

interface G2LogoProps extends HTMLAttributes<SVGElement> {}

const G2Logo = (props: G2LogoProps) => (
  <svg
    width="39"
    height="40"
    viewBox="0 0 39 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.1708 40C11.261 39.9759 3.49766 34.3478 0.965194 25.953C-1.14988 18.9188 0.221373 12.4893 4.97862 6.87052C7.90307 3.41764 11.6743 1.30123 16.1078 0.395534C18.5879 -0.110265 21.1424 -0.131162 23.6303 0.333995C23.9086 0.384832 23.9113 0.459749 23.8029 0.684501C22.5793 3.23169 21.3614 5.78244 20.1494 8.33676C20.1193 8.41664 20.0636 8.48428 19.991 8.52907C19.9183 8.57386 19.8329 8.59327 19.748 8.58426C17.1988 8.64943 14.7446 9.56529 12.7759 11.1861C10.8072 12.8069 9.43715 15.0396 8.88368 17.5288C7.66226 22.9697 10.6054 28.5979 15.7988 30.5925C19.9206 32.1752 23.7561 31.5451 27.2451 28.84C27.423 28.7062 27.4966 28.6795 27.6237 28.9002C28.9615 31.2414 30.3109 33.5781 31.6719 35.9103C31.791 36.115 31.7482 36.2073 31.5716 36.3304C28.9436 38.1897 25.9036 39.3833 22.7126 39.8087C21.8693 39.9174 21.0208 39.9813 20.1708 40Z"
      fill="#EF492D"
    />
    <path
      d="M34.0116 34.206C33.9019 34.1471 33.8711 34.0267 33.8149 33.9291C32.2934 31.298 30.7772 28.6643 29.2664 26.028C29.215 25.9199 29.1312 25.8307 29.0266 25.7726C28.922 25.7146 28.8019 25.6907 28.6831 25.7042C25.6623 25.7136 22.6429 25.7042 19.6221 25.7042H19.2462C19.2435 25.5882 19.2857 25.4756 19.3639 25.3898C20.898 22.7249 22.4329 20.06 23.9687 17.3951C24.0095 17.3069 24.0768 17.2338 24.1612 17.1858C24.2457 17.1378 24.343 17.1174 24.4396 17.1275C27.5317 17.1355 30.6229 17.1355 33.7133 17.1275C33.8093 17.1175 33.9061 17.1379 33.9899 17.1859C34.0737 17.234 34.1403 17.3071 34.1802 17.3951C35.7222 20.0823 37.2714 22.7659 38.8277 25.446C38.8797 25.5147 38.9089 25.5979 38.9113 25.684C38.9137 25.7701 38.8892 25.8548 38.8411 25.9263C37.2776 28.626 35.72 31.323 34.1681 34.0173C34.1267 34.0856 34.1079 34.1792 34.0116 34.206Z"
      fill="#EF492D"
    />
    <path
      d="M27.4993 5.90865C26.9455 5.3548 26.4291 4.82503 25.8939 4.30328C25.7281 4.14275 25.8444 4.02368 25.9154 3.90194C26.5277 2.83568 27.5247 2.04401 28.702 1.68921C29.4154 1.45377 30.1702 1.37023 30.9179 1.44396C31.6655 1.51769 32.3895 1.74705 33.0432 2.11731C35.4512 3.41899 35.2359 6.43843 33.8459 7.80299C33.3189 8.31043 32.722 8.73976 32.0733 9.07792C31.4044 9.43378 30.7355 9.76957 30.0799 10.1482C29.5355 10.4599 29.1114 10.8906 28.9147 11.5141C28.8345 11.7656 28.8826 11.8311 29.1515 11.8285C30.9201 11.8178 32.69 11.8285 34.4599 11.8178C34.7275 11.8178 34.8278 11.8686 34.8171 12.1602C34.793 12.785 34.8024 13.4111 34.8171 14.0332C34.8171 14.2312 34.7623 14.3007 34.5589 14.3007C31.7148 14.2954 28.8715 14.2954 26.0291 14.3007C25.8953 14.3007 25.7709 14.3007 25.7695 14.1067C25.7695 12.1375 26.1334 10.3034 27.6746 8.89865C28.4087 8.24856 29.2328 7.70786 30.1214 7.29328C30.6084 7.05515 31.1007 6.82773 31.5261 6.48257C31.898 6.18023 32.1549 5.81367 32.1816 5.31868C32.2218 4.54409 31.6091 3.96883 30.6833 3.90863C29.3522 3.81365 28.3916 4.41298 27.7201 5.52604C27.6532 5.63574 27.5916 5.74812 27.4993 5.90865Z"
      fill="#EF492D"
    />
  </svg>
);

export default G2Logo;
