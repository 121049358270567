import { HTMLAttributes } from "react";

interface TrackIconProps extends HTMLAttributes<SVGElement> {}

const TrackIcon = ({ ...props }: TrackIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.7872 17.1366C1.80221 17.4614 1.89424 17.7991 2.08641 18.1381C2.75174 19.3121 5.01185 20.1496 9.0061 18.4602C10.4463 17.8511 12.8642 15.9271 13.7279 14.7417C14.6532 14.4814 16.8329 13.8251 18.1491 13.2822C19.7945 12.6036 20.7688 11.4224 20.0725 10.2395C19.2829 8.89805 18.4795 8.6116 17.9492 8.4583L17.7079 8.12019M1.7872 17.1366C1.75023 16.3369 2.18018 15.6157 2.73185 15.1458C3.05549 14.87 3.71907 14.1539 4.44362 13.2611M1.7872 17.1366C2.97255 18.1647 6.10079 17.2003 6.62928 16.9733C10.3344 15.3813 10.7969 14.028 11.5781 13.3324C12.3592 12.6368 14.6063 11.6174 15.9652 10.8478C17.0522 10.2322 17.5799 8.77289 17.7079 8.12019M17.7079 8.12019C17.7079 8.12019 14.9609 1.70045 13.718 2.40433C12.4751 3.10821 13.8933 5.65981 12.5875 6.39931C11.2817 7.13881 8.72902 5.76777 8.72902 5.76777C8.61185 5.96196 8.4125 6.31846 8.16956 6.79593M4.44362 13.2611C5.45572 12.0139 6.5868 10.4219 7.0765 9.20389C7.4578 8.25548 7.85113 7.42179 8.16956 6.79593M4.44362 13.2611C4.77037 13.5815 5.5981 14.1492 6.29506 13.8557C7.16626 13.4889 9.58836 8.41432 9.46803 8.16598C9.37176 7.96731 8.56228 7.16983 8.16956 6.79593"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrackIcon;
