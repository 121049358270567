import { mq } from "@/constants/breakpoints";
import { css } from "@emotion/react";
import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLHRElement> {
  show?: boolean;
}
const FooterHR = ({ show = false, ...props }: Props) => {
  return (
    <hr
      css={css`
        border: 1px solid #374b57;
        margin: 30px 0;
        ${mq["md"]} {
          display: ${show ? "block" : "none"};
        }
      `}
      {...props}
    />
  );
};

export default FooterHR;
