import { HTMLAttributes } from "react";

const BigCrossIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 4L12 12M12 12L4 4M12 12L4 20M12 12L20 20"
      stroke="#D3D6E9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BigCrossIcon;
