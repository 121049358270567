import { HTMLAttributes } from "react";

interface LacrosseIconProps extends HTMLAttributes<SVGElement> {}

const LacrosseIcon = ({ ...props }: LacrosseIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.997 3.93464L1.72656 20.2735M12.106 2.52612L19.3996 9.85044M9.30071 4.21635L17.4359 12.3858M8.17862 7.59681L14.3501 13.7943M7.05652 10.9773L11.2644 15.2028M18.4566 12.362C17.3167 13.5067 15.8436 14.1129 14.3509 14.1805C13.1429 14.2352 11.8761 15.0876 11.0211 15.9462C9.66595 17.3071 7.46879 17.3071 6.11363 15.9462C4.75847 14.5853 4.75847 12.3789 6.11363 11.0181C6.96862 10.1595 7.81738 8.88736 7.87181 7.67437C7.93908 6.17524 8.54269 4.69586 9.68263 3.55112C12.1055 1.11805 16.0337 1.11805 18.4566 3.55112C20.8795 5.98418 20.8795 9.92896 18.4566 12.362Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LacrosseIcon;
