import G2Logo from "@/svg/G2Logo";
import TrustpilotLogo from "@/svg/TrustpilotLogo";
import { useMemo } from "react";

const useReviewSitesData = (type: "g2" | "trustpilot") => {
  return useMemo(() => {
    if (type === "g2") {
      return {
        Icon: G2Logo,
        label: `${process.env.g2Count}+ reviews`,
        href: "https://www.g2.com/products/jersey-watch/reviews",
      };
    } else {
      return {
        Icon: TrustpilotLogo,
        label: `${process.env.trustpilotCount}+ reviews`,
        href: "https://www.trustpilot.com/review/jerseywatch.com",
      };
    }
  }, [type]);
};

export default useReviewSitesData;
