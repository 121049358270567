import { iconColor } from "@/constants/colors";
import { css } from "@emotion/react";
import Link from "next/link";
import { Body } from "../Typography";

interface FooterSectionChildProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  href: string;
}

const FooterSectionChild = ({ href, ...props }: FooterSectionChildProps) => {
  return (
    <Link href={href}>
      <Body
        css={css`
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          color: ${iconColor};
          &:hover {
            color: #e8ebf9;
          }
        `}
        {...props}
      />
    </Link>
  );
};

export default FooterSectionChild;
