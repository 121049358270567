import { mq } from "@/constants/breakpoints";
import { iconColor } from "@/constants/colors";
import JWHeaderLogo from "@/svg/JWHeaderLogo";
import { css } from "@emotion/react";
import Link from "next/link";
import { HTMLAttributes } from "react";
import FooterReviewButton from "./FooterReviewButton";
import FooterSocial from "./FooterSocial";

interface FooterLogoReviewsSocialsProps
  extends HTMLAttributes<HTMLDivElement> {}

const FooterLogoReviewsSocials = ({
  ...props
}: FooterLogoReviewsSocialsProps) => {
  return (
    <div {...props}>
      <Link href="/" aria-label="Jersey Watch Logo">
        <JWHeaderLogo
          css={css`
            color: #fff;
            transition: color 0.2s ease-in-out;
            &:hover {
              color: ${iconColor};
            }
            ${mq["lg"]} {
              width: 195px;
              height: auto;
            }
          `}
        />
      </Link>
      <div
        css={css`
          margin-top: 30px;
          display: flex;
          gap: 15px;
          justify-content: center;
          align-items: center;
          ${mq["lg"]} {
            margin-top: 60px;
            flex-direction: column;
            align-items: flex-start;
          }
        `}
      >
        <FooterReviewButton type="g2" />
        <FooterReviewButton type="trustpilot" />
      </div>
      <div
        css={css`
          margin-top: 30px;
          display: flex;
          gap: 24px;
          align-items: center;
          justify-content: center;
          ${mq["lg"]} {
            margin-top: 40px;
          }
        `}
      >
        <FooterSocial type="youtube" />
        <FooterSocial type="facebook" />
        <FooterSocial type="instagram" />
        <FooterSocial type="linkedin" />
      </div>
    </div>
  );
};

export default FooterLogoReviewsSocials;
