import { dividerColor } from "@/constants/colors";
import { css } from "@emotion/react";

interface HRProps {}

const HR = ({}: HRProps) => {
  return (
    <hr
      css={css`
        border-top: 1px solid ${dividerColor};
      `}
    />
  );
};

export default HR;
