import { HTMLAttributes } from "react";

interface HockeyIconProps extends HTMLAttributes<SVGElement> {}

const HockeyIcon = ({ ...props }: HockeyIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.2873 14.7234C3.02278 15.7968 3.62418 16.6743 4.28376 17.6367C6.08275 20.2619 10.9716 20.0364 15.2032 17.1331C19.4349 14.2299 21.407 9.74819 19.608 7.12303L17.6115 4.20974M17.7166 4.36309C19.5156 6.98824 17.5435 11.4699 13.3118 14.3732C9.08016 17.2765 4.19135 17.5019 2.39236 14.8768C0.593372 12.2516 2.56544 7.76996 6.79711 4.86668C11.0288 1.9634 15.9176 1.73794 17.7166 4.36309Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default HockeyIcon;
