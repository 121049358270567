import { useState, useEffect } from "react";

const useIsScrolledDown = (yPosition: number) => {
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > yPosition) {
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [yPosition]);
  return isScrolledDown;
};

export default useIsScrolledDown;
