import { blueColor, lightBlueColor, darkColor } from "@/constants/colors";
import navbarData from "@/constants/navbarData";
import { css } from "@emotion/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import { H8_16 } from "../Typography";

interface NavbarDesktopFolderTooltipSportsProps {}

const NavbarDesktopFolderTooltipSports =
  ({}: NavbarDesktopFolderTooltipSportsProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { asPath } = useRouter();
    useEffect(() => {
      setIsOpen(false);
    }, [asPath]);
    return (
      <Tooltip
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        anchorSelect={`.desktop-header-folder-sports`}
        place="bottom"
        noArrow
        css={css`
          z-index: 2;
          padding: 30px;
          border-radius: 15px;
          background-color: #fff;
          display: grid;
          grid-row-gap: 5px;
          grid-column-gap: 14px;
          grid-template-columns: repeat(4, 1fr);
          opacity: 1;
          transition: opacity 0.2s ease-in-out;
          box-shadow: 0px 8px 30px rgba(116, 134, 196, 0.24);
        `}
        clickable
      >
        <style global jsx>{`
          .react-tooltip {
            left: 50% !important;
            transform: translateX(-50%);
          }
        `}</style>
        {navbarData.sports.map(({ title, Icon, href }, index) => {
          return (
            <Link href={href} key={index}>
              <div
                css={css`
                  display: flex;
                  gap: 14px;
                  align-items: center;
                  padding: 12px 24px;
                  border: 2px solid transparent;
                  transition: border-color 0.2s ease-in-out;
                  border-radius: 10px;
                  &:hover {
                    border-color: ${blueColor};
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    width: 50px;
                    height: 50px;
                    background-color: ${blueColor};
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                  `}
                >
                  <Icon
                    css={css`
                      width: 24px;
                      height: 24px;
                      color: ${lightBlueColor};
                    `}
                  />
                </div>
                <div>
                  <H8_16
                    css={css`
                      color: ${darkColor};
                    `}
                  >
                    {title}
                  </H8_16>
                </div>
              </div>
            </Link>
          );
        })}
      </Tooltip>
    );
  };

export default NavbarDesktopFolderTooltipSports;
