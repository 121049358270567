import { mq } from "@/constants/breakpoints";
import { css } from "@emotion/react";
import NavbarDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

const Header = () => {
  return (
    <header>
      <div
        css={css`
          display: block;
          ${mq["lg"]} {
            display: none;
          }
        `}
      >
        <HeaderMobile />
      </div>
      <div
        css={css`
          display: none;
          ${mq["lg"]} {
            display: block;
          }
        `}
      >
        <NavbarDesktop />
      </div>
    </header>
  );
};

export default Header;
