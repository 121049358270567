import { mq } from "@/constants/breakpoints";
import { css } from "@emotion/react";
import { H7Css, H8_16Css, Heading } from "../Typography";

interface Props {
  label: string;
  children: React.ReactNode;
}

const FooterSectionParent = ({ label, children }: Props) => {
  return (
    <div>
      <Heading
        level={3}
        css={css`
          color: #fff;
          ${H8_16Css}
          ${mq["lg"]} {
            ${H7Css}
          }
        `}
      >
        {label}
      </Heading>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px 30px;
          margin-top: 30px;
          ${mq["sm"]} {
            grid-template-columns: repeat(3, 1fr);
          }
          ${mq["md"]} {
            display: flex;
            flex-direction: column;
          }
        `}
      >
        {children}
      </div>
    </div>
  );
};

export default FooterSectionParent;
