import { HTMLAttributes } from "react";

interface BackgroundChecksIconProps extends HTMLAttributes<SVGElement> {}

const BackgroundChecksIcon = ({ ...props }: BackgroundChecksIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.92773"
        y="1.92798"
        width="18.144"
        height="18.144"
        rx="3"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M8.47949 8.98407L10.4955 11.0001L13.5195 7.97607"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.92773 9.99194V13.0399C1.92773 14.6968 3.27088 16.0399 4.92773 16.0399H17.0717C18.7286 16.0399 20.0717 14.6968 20.0717 13.0399V9.99194"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default BackgroundChecksIcon;
