import { darkColor, lightColor } from "@/constants/colors";
import SmallArrow from "@/svg/SmallArrow";
import { css } from "@emotion/react";
import { H8_16 } from "../Typography";

interface Props {
  label: string;
  open: boolean;
  onClick: () => void;
  children: React.ReactNode[];
}

const NavbarMobileDialogAccordion = ({
  label,
  open,
  onClick,
  children,
}: Props) => {
  return (
    <div>
      <div
        role="button"
        onClick={onClick}
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0;
          cursor: pointer;
        `}
      >
        <H8_16
          css={css`
            color: ${darkColor};
          `}
        >
          {label}
        </H8_16>
        <SmallArrow
          css={css`
            color: ${lightColor};
          `}
        />
      </div>
      <div
        css={css`
          transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
          overflow: hidden;
        `}
        style={{
          maxHeight: open ? 1000 : 0,
          opacity: open ? 1 : 0,
          visibility: open ? "visible" : "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default NavbarMobileDialogAccordion;
