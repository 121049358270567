import Head from "next/head";
import { useRouter } from "next/router";
import { useMemo } from "react";

const ldJson = () => ({
  "@context": "http://www.schema.org",
  "@type": "Organization",
  name: "Jersey Watch",
  url: "https://www.jerseywatch.com",
  logo: "https://jerseywatch-assets.s3.amazonaws.com/global/jw-trophy.png",
  foundingDate: "2011",
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "Customer Support",
    email: "contact@jerseywatch.com",
  },
  sameAs: [
    "https://angel.co/company/jersey-watch/",
    "https://www.linkedin.com/company/jersey-watch/",
    "https://www.crunchbase.com/organization/jersey-watch",
    "https://www.facebook.com/JerseyWatch",
    "https://www.twitter.com/jersey_watch",
    "https://www.youtube.com/user/JerseyWatchNetwork",
    "https://www.softwareadvice.com/ca/sports-league-management/jersey-watch-profile/",
    "https://www.capterra.com/p/178257/Jersey-Watch/",
    "https://www.trustpilot.com/review/jerseywatch.com",
    "https://www.g2.com/products/jersey-watch/reviews",
    "https://www.glassdoor.com/Overview/Working-at-Jersey-Watch-EI_IE1339717.11,23.htm",
    "https://www.getapp.com/recreation-wellness-software/a/jersey-watch/",
    "https://www.instagram.com/jersey.watch/",
  ],
});

interface SEOProps {
  title: string;
  description: string;
  imageUrl: string;
  isArticle?: boolean;
}

const SEO = ({ title, description, imageUrl, isArticle }: SEOProps) => {
  const { asPath } = useRouter();
  const url = `${process.env.NEXT_PUBLIC_SITE_URL}${asPath}`;
  const imageUrlMod = imageUrl.startsWith("//")
    ? `https:${imageUrl}`
    : imageUrl;
  const modTitle = title.startsWith("Jersey Watch")
    ? title
    : `${title} | Jersey Watch`;

  const ldJsonD = JSON.stringify(ldJson());

  const metaDescription = useMemo(() => {
    return description.replace("ORG_COUNT", process.env.organizationCount!);
  }, [description]);

  return (
    <Head>
      <title>{modTitle}</title>
      <meta name="description" content={metaDescription} key="desc" />
      <meta name="image" content={imageUrlMod} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={isArticle ? "article" : "website"} />
      <meta property="og:title" content={modTitle} />
      <meta property="og:description" content={metaDescription} />
      {imageUrl && <meta property="og:image" content={imageUrlMod} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@Jersey_Watch" />
      <meta name="twitter:title" content={modTitle} />
      <meta name="twitter:description" content={metaDescription} />
      {imageUrl && <meta name="twitter:image" content={imageUrlMod} />}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ldJsonD }}
      />
    </Head>
  );
};

export default SEO;
