import Script from "next/script";

interface GTagProps {}

const GTag = ({}: GTagProps) => {
  return (
    <>
      <Script
        id="gtag-remote-script"
        src="https://www.googletagmanager.com/gtag/js?id=G-PSMKJX9M4T"
        strategy="afterInteractive"
      />
      <Script
        id="gtag-inline-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        
            gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}'); 
            gtag('config', '${process.env.NEXT_PUBLIC_GA_V4_ID}'); 
            ${
              process.env.NODE_ENV === "production"
                ? "gtag('config', 'AW-977819622');"
                : ""
            }
        `,
        }}
      />
    </>
  );
};

export default GTag;
