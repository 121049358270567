import { HTMLAttributes } from "react";

interface CommunicationsIconProps extends HTMLAttributes<SVGElement> {}

const CommunicationsIcon = ({ ...props }: CommunicationsIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_700_45491" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.0801 13.8505C21.0801 15.5074 19.7369 16.8505 18.0801 16.8505L11 16.8505L7.11192 20.7916C6.48394 21.4281 5.40005 20.9835 5.40005 20.0893L5.40005 16.8505L3.92008 16.8505C2.26322 16.8505 0.920078 15.5074 0.920078 13.8505L0.920078 3.95696C0.920077 2.3001 2.26323 0.956958 3.92008 0.956958L18.0801 0.956957C19.7369 0.956957 21.0801 2.3001 21.0801 3.95695L21.0801 13.8505Z"
        />
      </mask>
      <path
        d="M18.0801 16.8505L18.0801 18.8505L18.0801 16.8505ZM11 16.8505L9.57629 15.4459L10.1637 14.8505L11 14.8505L11 16.8505ZM7.11192 20.7916L5.68817 19.387L5.68817 19.387L7.11192 20.7916ZM5.40005 16.8505L5.40005 14.8505L7.40005 14.8505L7.40005 16.8505L5.40005 16.8505ZM18.0801 14.8505C18.6324 14.8505 19.0801 14.4028 19.0801 13.8505L23.0801 13.8505C23.0801 16.612 20.8415 18.8505 18.0801 18.8505L18.0801 14.8505ZM11 14.8505L18.0801 14.8505L18.0801 18.8505L11 18.8505L11 14.8505ZM12.4238 18.2551L8.53567 22.1962L5.68817 19.387L9.57629 15.4459L12.4238 18.2551ZM8.53567 22.1962C6.65172 24.1058 3.40005 22.7718 3.40005 20.0893L7.40005 20.0893C7.40005 19.1951 6.31615 18.7505 5.68817 19.387L8.53567 22.1962ZM3.40005 20.0893L3.40005 16.8505L7.40005 16.8505L7.40005 20.0893L3.40005 20.0893ZM3.92008 14.8505L5.40005 14.8505L5.40005 18.8505L3.92008 18.8505L3.92008 14.8505ZM2.92008 13.8505C2.92008 14.4028 3.36779 14.8505 3.92008 14.8505L3.92008 18.8505C1.15866 18.8505 -1.07992 16.612 -1.07992 13.8505L2.92008 13.8505ZM2.92008 3.95696L2.92008 13.8505L-1.07992 13.8505L-1.07992 3.95696L2.92008 3.95696ZM3.92008 2.95696C3.36779 2.95696 2.92008 3.40468 2.92008 3.95696L-1.07992 3.95696C-1.07992 1.19553 1.15866 -1.04304 3.92008 -1.04304L3.92008 2.95696ZM18.0801 2.95696L3.92008 2.95696L3.92008 -1.04304L18.0801 -1.04304L18.0801 2.95696ZM19.0801 3.95696C19.0801 3.40467 18.6324 2.95696 18.0801 2.95696L18.0801 -1.04304C20.8415 -1.04304 23.0801 1.19553 23.0801 3.95695L19.0801 3.95696ZM19.0801 13.8505L19.0801 3.95696L23.0801 3.95695L23.0801 13.8505L19.0801 13.8505Z"
        fill="#048CDD"
        mask="url(#path-1-inside-1_700_45491)"
      />
    </svg>
  );
};

export default CommunicationsIcon;
