import { HTMLAttributes } from "react";

interface WrestlingIconProps extends HTMLAttributes<SVGElement> {}

const WrestlingIcon = ({ ...props }: WrestlingIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.098 8.64979L9.19581 7.80155V7.80155C7.5273 7.31387 5.74498 8.11522 5.00286 9.68674L1.36737 17.3852C1.1251 17.8983 1.36518 18.5063 1.89248 18.7151V18.7151C2.36153 18.9008 2.89761 18.7038 3.13525 18.2583L5.55263 13.727L7.90488 16.1567L7.63131 18.1336C7.56098 18.6418 7.90363 19.1135 8.40997 19.2054V19.2054C8.932 19.3002 9.43511 18.9573 9.53609 18.4378L10.1008 15.5326L8.18482 12.6051L8.9045 10.823"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="9.30364"
        cy="4.50099"
        rx="1.79632"
        ry="1.77809"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="15.4706"
        cy="5.1172"
        rx="1.79632"
        ry="1.77809"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7012 11.0288L13.2959 10.4361L16.8807 8.53518C17.9394 7.97377 19.25 8.4779 19.6596 9.60407V9.60407C20.3112 11.3957 20.1932 13.3769 19.3337 15.0785L19.084 15.5728L20.5104 17.6907C20.8213 18.1523 20.6862 18.7799 20.2129 19.0727V19.0727C19.7951 19.3312 19.2505 19.2406 18.9388 18.8609L16.8885 16.3631L17.0881 14.3874L14.794 18.4424C14.5168 18.9324 13.897 19.1084 13.4036 18.8371V18.8371C12.904 18.5623 12.7234 17.9335 13.001 17.4355L15.6909 12.6094L15.0922 11.424"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WrestlingIcon;
