import useNavbarFontColor from "@/hooks/useNavbarFontColor";
import BurgerIcon from "@/svg/BurgerIcon";
import JWHeaderLogo from "@/svg/JWHeaderLogo";
import { css } from "@emotion/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import NavbarMobileDialog from "../NavbarMobileDialog/NavbarMobileDialog";
import { dividerColor } from "@/constants/colors";
import GetStartedButton from "../Buttons/GetStartedButton";
import { mq } from "@/constants/breakpoints";

const HeaderMobile = () => {
  const color = useNavbarFontColor();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { asPath, pathname } = useRouter();
  useEffect(() => {
    setModalIsOpen(false);
  }, [asPath]);
  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid
            ${pathname === "/sign-up" ? dividerColor : "transparent"};
        `}
      >
        <Link
          href="/"
          aria-label="Jersey Watch Logo"
          css={css`
            height: 60px;
            padding-left: 20px;
          `}
        >
          <JWHeaderLogo
            css={css`
              height: 60px;
              color: ${color};
            `}
          />
        </Link>

        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <GetStartedButton />
          <div
            role="button"
            aria-label="Open Mobile Navigation"
            onClick={() => setModalIsOpen(true)}
            css={css`
              height: 60px;
              width: 48px;
              ${mq["sm"]} {
                width: 60px;
              }
              color: ${color};
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <BurgerIcon />
          </div>
        </div>
      </div>
      <NavbarMobileDialog
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      />
    </>
  );
};

export default HeaderMobile;
