import { mq } from "@/constants/breakpoints";
import { css } from "@emotion/react";
import { HTMLAttributes } from "react";

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {}

const Container = ({ ...props }: ContainerProps) => {
  return (
    <div
      css={css`
        max-width: 1192px;
        margin: auto;
        padding-left: 16px;
        padding-right: 16px;
        ${mq["sm"]} {
          padding-left: 40px;
          padding-right: 40px;
        }
      `}
      {...props}
    />
  );
};

export default Container;
