import { H9Css, Heading } from "@/components/Typography";
import { css } from "@emotion/react";

const Svg = ({ color }: { color: string }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        color: ${color};
      `}
    >
      <path
        d="M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37255 6.37258 0.999969 13 0.999969C19.6274 0.999969 25 6.37255 25 13Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6006 13.4245C17.9139 13.2287 17.9139 12.7723 17.6006 12.5765L11.364 8.67862C11.0309 8.47048 10.599 8.7099 10.599 9.10261V16.8984C10.599 17.2911 11.0309 17.5305 11.364 17.3224L17.6006 13.4245Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const WatchDemoNavbar = ({ color }: { color: string }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 9px;
      `}
    >
      <Svg color={color} />
      <Heading
        css={css`
          color: ${color};
          ${H9Css}
        `}
      >
        Watch Demo
      </Heading>
    </div>
  );
};

export default WatchDemoNavbar;
