import { HTMLAttributes } from "react";

interface VolleyballIconProps extends HTMLAttributes<SVGElement> {}

const VolleyballIcon = ({ ...props }: VolleyballIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0001 10.9999C11.8249 12.4608 13.0672 13.6422 14.5678 14.3925C16.0684 15.1428 17.7589 15.4278 19.4225 15.2111M11.0001 10.9999C9.32252 10.9837 7.67837 11.4688 6.27833 12.3931C4.87828 13.3175 3.78619 14.6389 3.14205 16.188M11.0001 10.9999C11.8529 9.5551 12.2546 7.88873 12.1541 6.21408C12.0535 4.53943 11.4552 2.93297 10.4357 1.60061M6.2625 12.5791C7.12301 14.3343 8.3791 15.8659 9.93192 17.0533C11.4847 18.2408 13.2919 19.0517 15.2113 19.4223M12.0014 6.10743C10.0509 5.97487 8.09631 6.29677 6.29137 7.0478C4.48644 7.79882 2.88042 8.95847 1.5997 10.4355M14.7364 14.3132C15.8263 12.6904 16.5248 10.8369 16.7769 8.89844C17.029 6.95998 16.8278 4.98945 16.1892 3.14191M20.4753 10.9999C20.4753 16.2329 16.2331 20.4751 11.0001 20.4751C5.76709 20.4751 1.5249 16.2329 1.5249 10.9999C1.5249 5.76685 5.76709 1.52466 11.0001 1.52466C16.2331 1.52466 20.4753 5.76685 20.4753 10.9999Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VolleyballIcon;
