import { HTMLAttributes } from "react";

const WebsiteIcon = (props: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.92773 4.35096C1.92773 3.84199 2.11889 3.35387 2.45916 2.99397C2.79943 2.63407 3.26093 2.43188 3.74213 2.43188H18.2573C18.7385 2.43188 19.2 2.63407 19.5403 2.99397C19.8806 3.35387 20.0717 3.84199 20.0717 4.35096V12.9868C20.0717 13.4958 19.8806 13.9839 19.5403 14.3438C19.2 14.7037 18.7385 14.9059 18.2573 14.9059H3.74213C3.26093 14.9059 2.79943 14.7037 2.45916 14.3438C2.11889 13.9839 1.92773 13.4958 1.92773 12.9868V4.35096Z"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.59766 19.4419H14.4017"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WebsiteIcon;
