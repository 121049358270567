import { HTMLAttributes } from "react";

interface BasketballIconProps extends HTMLAttributes<SVGElement> {}

const BasketballIcon = ({ ...props }: BasketballIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0001 20.4751C16.2331 20.4751 20.4753 16.2329 20.4753 10.9999C20.4753 5.76685 16.2331 1.52466 11.0001 1.52466C5.76709 1.52466 1.5249 5.76685 1.5249 10.9999C1.5249 16.2329 5.76709 20.4751 11.0001 20.4751Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.89355 5.47266C7.05196 8.63477 7.05196 13.7597 3.89355 16.9219"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.1066 16.9219C14.9482 13.7597 14.9482 8.63477 18.1066 5.47266"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11 1.52466V20.4751"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M1.91992 11.3948H20.0807"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BasketballIcon;
