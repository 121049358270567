import { HTMLAttributes } from "react";

interface CalendarIconProps extends HTMLAttributes<SVGElement> {}

const CalendarIcon = ({ ...props }: CalendarIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.92773 5.94409C1.92773 4.83952 2.82316 3.94409 3.92773 3.94409H18.0717C19.1763 3.94409 20.0717 4.83952 20.0717 5.94409V18.0721C20.0717 19.1767 19.1763 20.0721 18.0717 20.0721H3.92774C2.82317 20.0721 1.92773 19.1767 1.92773 18.0721V5.94409Z"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.47168 1.92798L7.47168 5.95998"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.98389 11L5.95989 11"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.0396 11L13.0156 11"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.98389 15.032L5.95989 15.032"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.0396 15.032L13.0156 15.032"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M14.5278 1.92798L14.5278 5.95998"
        stroke="#048CDD"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CalendarIcon;
