interface TrustpilotLogoProps {}

const TrustpilotLogo = ({ ...props }: TrustpilotLogoProps) => {
  return (
    <svg
      width="43"
      height="40"
      viewBox="0 0 43 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.062 15.2868H26L21.0388 0L16.062 15.2868L0 15.2713L13.0078 24.7287L8.03101 40L21.0388 30.5581L34.031 40L29.0698 24.7287L42.062 15.2868Z"
        fill="#00B67A"
      />
      <path
        d="M30.1863 28.1839L29.0701 24.7266L21.0391 30.556L30.1863 28.1839Z"
        fill="#005128"
      />
    </svg>
  );
};

export default TrustpilotLogo;
