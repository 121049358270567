import { HTMLAttributes } from "react";

interface BaseballIconProps extends HTMLAttributes<SVGElement> {}

const BaseballIcon = ({ ...props }: BaseballIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 20.6768C16.3444 20.6768 20.6768 16.3444 20.6768 11C20.6768 5.65569 16.3444 1.32324 11 1.32324C5.65569 1.32324 1.32324 5.65569 1.32324 11C1.32324 16.3444 5.65569 20.6768 11 20.6768Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.5742 4.49854C15.7595 6.31297 13.2984 7.33231 10.7322 7.33231C8.16596 7.33231 5.70485 6.31297 3.89014 4.49854"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.89014 16.947C5.70485 15.1326 8.16596 14.1133 10.7322 14.1133C13.2984 14.1133 15.7595 15.1326 17.5742 16.947"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BaseballIcon;
