import BackgroundChecksIcon from "@/svg/NavbarIcons/BackgroundChecksIcon";
import BaseballIcon from "@/svg/NavbarIcons/BaseballIcon";
import BasketballIcon from "@/svg/NavbarIcons/BasketballIcon";
import CalendarIcon from "@/svg/NavbarIcons/CalendarIcon";
import CardIcon from "@/svg/NavbarIcons/CardIcon";
import CheerleadingIcon from "@/svg/NavbarIcons/CheerleadingIcon";
import CommunicationsIcon from "@/svg/NavbarIcons/CommunicationsIcon";
// import DollarIcon from "@/svg/NavbarIcons/DollarIcon";
import FootballIcon from "@/svg/NavbarIcons/FootballIcon";
import HockeyIcon from "@/svg/NavbarIcons/HockeyIcon";
import LacrosseIcon from "@/svg/NavbarIcons/LacrosseIcon";
import OtherSportsIcon from "@/svg/NavbarIcons/OtherSportsIcon";
import SoccerIcon from "@/svg/NavbarIcons/SoccerIcon";
import SoftballIcon from "@/svg/NavbarIcons/SoftballIcon";
import TrackIcon from "@/svg/NavbarIcons/TrackIcon";
import VolleyballIcon from "@/svg/NavbarIcons/VolleyballIcon";
import WebsiteIcon from "@/svg/NavbarIcons/WebsiteIcon";
import WrestlingIcon from "@/svg/NavbarIcons/WrestlingIcon";
import {
  aboutUsPath,
  backgroundCheckPath,
  baseballPath,
  basketballPath,
  blogPath,
  cheerPath,
  communicationPath,
  // donationsPath,
  faqPath,
  footballPath,
  helpCenterPath,
  hockeyPath,
  lacrossePath,
  otherSportsPath,
  pricingPath,
  registrationPath,
  schedulingPath,
  soccerPath,
  softballPath,
  trackPath,
  volleyballPath,
  websiteBuilderPath,
  wrestlingPath,
} from "./paths";

const navbarData = {
  features: [
    {
      title: "Sports Website Builder",
      description: "Create & Edit Your Website",
      Icon: WebsiteIcon,
      href: websiteBuilderPath,
    },
    {
      title: "Online Registration",
      description: "Manage Registration & Payments",
      Icon: CardIcon,
      href: registrationPath,
    },
    {
      title: "Communication Tools",
      description: "Send Email and Text Notifications",
      Icon: CommunicationsIcon,
      href: communicationPath,
    },
    {
      title: "Scheduling",
      description: "Schedule Games and Events",
      Icon: CalendarIcon,
      href: schedulingPath,
    },
    {
      title: "Background Checks",
      description: "Keep Players Safe",
      Icon: BackgroundChecksIcon,
      href: backgroundCheckPath,
    },
    // {
    //   title: "Donations",
    //   description: "Collect Donations Online",
    //   Icon: DollarIcon,
    //   href: donationsPath,
    // },
  ],
  sports: [
    {
      title: "Baseball",
      href: baseballPath,
      Icon: BaseballIcon,
    },
    { title: "Basketball", href: basketballPath, Icon: BasketballIcon },
    {
      title: "Cheerleading",
      href: cheerPath,
      Icon: CheerleadingIcon,
    },
    { title: "Football", href: footballPath, Icon: FootballIcon },
    { title: "Hockey", href: hockeyPath, Icon: HockeyIcon },
    { title: "Lacrosse", href: lacrossePath, Icon: LacrosseIcon },
    { title: "Soccer", href: soccerPath, Icon: SoccerIcon },
    { title: "Softball", href: softballPath, Icon: SoftballIcon },
    {
      title: "Track & Field",
      href: trackPath,
      Icon: TrackIcon,
    },
    { title: "Volleyball", href: volleyballPath, Icon: VolleyballIcon },
    { title: "Wrestling", href: wrestlingPath, Icon: WrestlingIcon },
    { title: "Other Sports", href: otherSportsPath, Icon: OtherSportsIcon },
  ],
  resources: [
    {
      title: "Pricing",
      href: pricingPath,
    },
    { title: "Blog", href: blogPath },
    { title: "Help Center", href: helpCenterPath },
    { title: "FAQ", href: faqPath },
    { title: "About Us", href: aboutUsPath },
  ],
};

export default navbarData;
