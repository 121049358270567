export const backgroundCheckPath = "/features/youth-sports-background-checks";
export const communicationPath = "/features/sports-team-communication-app";
export const donationsPath = "/features/sports-donations";
export const summaryPath = "/features/summary";
export const registrationPath = "/features/sports-registration-software";
export const schedulingPath = "/features/sports-scheduling-software";
export const websiteBuilderPath = "/features/sports-website-builder";

export const baseballPath = "/sports/baseball";
export const basketballPath = "/sports/basketball";
export const cheerPath = "/sports/cheerleading";
export const footballPath = "/sports/football";
export const hockeyPath = "/sports/hockey";
export const lacrossePath = "/sports/lacrosse";
export const soccerPath = "/sports/soccer";
export const softballPath = "/sports/softball";
export const trackPath = "/sports/track-and-field";
export const volleyballPath = "/sports/volleyball";
export const wrestlingPath = "/sports/wrestling";
export const otherSportsPath = "/sports/more-sports";

const appHostname = process.env.appHostname;

export const aboutUsPath = "/about";
export const blogPath = "/blog";
export const cookiesPath = "/cookie-policy";
export const demoPath = "/demo";
export const emailCheckUrl = `https://${appHostname}/org/sign_up/email/check`;
export const emailUrl = `https://${appHostname}/org/sign_up/email`;
export const faqPath = "/faq";
export const googleCheckUrl = `https://${appHostname}/org/sign_up/google/check`;
export const googleUrl = `https://${appHostname}/org/sign_up/google`;
export const helpCenterPath = "https://help.jerseywatch.com";
export const pricingPath = "/pricing";
export const privacyPath = "/privacy";
export const signInUrl = `https://${appHostname}/users/sign_in`;
export const signUpPath = "/sign-up";
export const termsPath = "/terms";

export const blogCategoryPath = (category: string, page: number) =>
  category === "all"
    ? "/blog"
    : `/blog/category/${category}${page === 1 ? "" : `/${page}`}`;
export const blogAuthorPath = (author: string, page: number) =>
  page === 1 ? `/blog/author/${author}` : `/blog/author/${author}/${page}`;
export const blogPostPath = (slug: string) => `/blog/${slug}`;
export const blogIndexPath = (page: number) =>
  page === 1 ? "/blog" : `/blog/${page}`;
